// Vendor Imports
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component, KeyboardEventHandler } from 'react';
import ReactDatePicker from 'react-datepicker';

// Project Imports
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';

import './index.scss';

// Constants
import { RELATIVE_FILTERS } from 'common/dates';
const scope = 'shared.components.date_picker';

export interface DatePickerProps {
  date?: string;
  dateFormat?: string;
  disabled?: boolean;
  onChangeDate: (date?: string) => void;
  showTodayButton?: boolean;
  showYesterdayButton?: boolean;
  allowDateClearing?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
}

// react-datepicker does not accept an onKeyUp handler,
// and Escape keys were causing undesireable effects
// in container components (like closing modals).
const onKeyUp = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    // Prevent ESCAPE from closing the modal unexpectantly
    event.preventDefault();
    event.stopPropagation();
  }
};

/**
  Basic wrapper around react-datepicker and SocrataIcon.
  @prop date Should be in the format YYYY-MM-DD
  @prop onChangeDate calls back with YYYY-MM-DD format.
  @prop disabled set to true if you want to gray out date picker
*/
export class DatePicker extends Component<DatePickerProps> {
  node: HTMLElement | null;
  datePickerRef: React.RefObject<ReactDatePicker>;

  constructor(props: DatePickerProps) {
    super(props);

    this.datePickerRef = React.createRef();

    _.bindAll(this, ['onClickRelativeDateButton']);
  }

  componentDidMount() {
    // This combats the real event listener on `document`
    // for the modal. See https://github.com/facebook/react/issues/7094
    this.node?.addEventListener('keyup', onKeyUp);
  }

  componentWillUnmount() {
    this.node?.removeEventListener('keyup', onKeyUp);
  }

  onClickRelativeDateButton({ event, relativeDate }: { event: React.MouseEvent; relativeDate: string }) {
    event.stopPropagation();
    event.preventDefault();

    this.props.onChangeDate(relativeDate);

    this.datePickerRef.current?.setOpen(false);
    // This isn't currently included on the typescript definition, but does exist.
    // @ts-expect-error TS(2339) FIXME: Property 'cancelFocusInput' does not exist on type... Remove this comment to see the full error message
    this.datePickerRef.current?.cancelFocusInput();
  }

  renderRelativeDateButtons() {
    const { showTodayButton, showYesterdayButton } = this.props;

    let yesterdayButton;
    let todayButton;

    if (showYesterdayButton) {
      const yesterdayButtonProps = {
        className: classNames('btn btn-yesterday', {
          'btn-left': showTodayButton
        }),
        onClick: (event: React.MouseEvent) =>
          this.onClickRelativeDateButton({
            event,
            relativeDate: RELATIVE_FILTERS.YESTERDAY
          })
      };

      yesterdayButton = <button {...yesterdayButtonProps}>{I18n.t('yesterday', { scope })}</button>;
    }

    if (showTodayButton) {
      const todayButtonProps = {
        className: classNames('btn btn-today', {
          'btn-right': showYesterdayButton
        }),
        onClick: (event: React.MouseEvent) =>
          this.onClickRelativeDateButton({
            event,
            relativeDate: RELATIVE_FILTERS.TODAY
          })
      };

      todayButton = <button {...todayButtonProps}>{I18n.t('today', { scope })}</button>;
    }

    return (
      <div className="relative-date-buttons-container">
        {yesterdayButton}
        {todayButton}
      </div>
    );
  }

  render() {
    const { date, dateFormat, disabled, onChangeDate, allowDateClearing, minDate, maxDate } = this.props;
    const datePickerProps = {
      ariaLabelledBy: 'date-input',
      className: 'text-input date-picker-input',
      dateFormat: dateFormat,
      dateFormatCalendar: 'MMMM yyyy',
      disabled,
      fixedHeight: true,
      isClearable: allowDateClearing,
      minDate: minDate,
      maxDate: maxDate,
      onChange: (value: Date) => {
        if (value) {
          onChangeDate(moment(value).format('YYYY-MM-DD'));
        } else {
          onChangeDate();
        }
      },
      placeholderText: I18n.t('placeholder', { scope }),
      ref: this.datePickerRef,
      selected: date === undefined || date === null ? null : moment(date).toDate(),
      todayButton: this.renderRelativeDateButtons()
    };

    return (
      <div
        ref={(node) => (this.node = node)}
        onKeyUp={onKeyUp as unknown as KeyboardEventHandler<HTMLDivElement>}
        className="socrata-date-picker"
      >
        <SocrataIcon name={IconName.Date} />
        <ReactDatePicker {...datePickerProps} />
        <label id="date-input" className="screenreader-only">
          {I18n.t('date_selector', { scope })}
        </label>
      </div>
    );
  }
}

export default DatePicker;
