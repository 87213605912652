import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import I18n from 'common/i18n';
import { getCurrentDomain } from 'common/currentDomain';
import { handleEnter } from 'common/dom_helpers/keyPressHelpers';

import * as constants from 'common/components/AssetBrowser/lib/constants';
import { changeSortOrder } from '../actions/sort_order';
import { DefaultResultListRowActions } from './default_result_list_row_actions';
import ResultListRowSelectButton from './result_list_row_select_button';
import ResultListRow from './result_list_row';

// NOTE: exported for testing
export const resultListRowProps = (result, transferOwnership, updateAudience) => {
  const source = result.metadata.domain;
  const sourceLink = result.source_link;
  const isFederated = source !== getCurrentDomain();
  const uid = result.resource.id;
  const analysisId = result.resource.analysisId;
  return _.merge(
    {
      analysisId,
      approvals: result.metadata.approvals,
      category: result.classification.domain_category,
      grants: result.metadata.grants,
      isExplicitlyHidden: result.metadata.is_hidden,
      isOwner: result.owner.id === _.get(window.socrata, 'currentUser.id'),
      isParent: result.metadata.is_parent,
      isPublic: result.metadata.is_public,
      isPublished: result.metadata.is_published,
      isCatalogFederatedView: isFederated && isEmpty(sourceLink),
      isDataFederatedView: isFederated && !isEmpty(sourceLink),
      link: result.link,
      owner: result.owner,
      creator: result.creator,
      publishedCopy: result.published_copy,
      source,
      sourceLink,
      transferOwnership,
      uid,
      updateAudience,
      visibleToAnonymous: result.metadata.visible_to_anonymous,
      visibleToSite: result.metadata.visible_to_site
    },
    _.pick(result.resource, ['description', 'name', 'provenance', 'type', 'updatedAt'])
  );
};

const isColumnSortable = (columnNameOrDefinition) => {
  // Sorting on the following columns is not supported by Cetera
  const unsortableColumns = [
    constants.COLUMN_ACTIONS,
    constants.COLUMN_APPROVAL_STATUS,
    constants.COLUMN_INTENDED_AUDIENCE,
    constants.COLUMN_AUDIENCE,
    constants.COLUMN_SOURCE
  ];

  return _.isString(columnNameOrDefinition) & !_.includes(unsortableColumns, columnNameOrDefinition);
};

export class ResultListTable extends Component {
  onColumnHeaderClick = (columnName) => {
    if (isColumnSortable(columnName)) {
      this.props.changeSortOrder(columnName);
    }
  };

  render() {
    const {
      closeOnSelect,
      columns,
      onAssetSelected,
      onClose,
      openInNewTab,
      order,
      results,
      selectMode,
      showFilters,
      transferOwnership,
      updateAudience
    } = this.props;

    let { actionElement } = this.props;

    // If someone explicitly passed in an actionElement, respect that.
    // If we're in AssetSelector mode, display a select button.
    // Otherwise, default to a generic list of actions.
    actionElement ||= selectMode ? ResultListRowSelectButton : DefaultResultListRowActions;

    const columnHeaderProps = (columnName) => {
      const columnIsActive = columnName === _.get(order, 'value');

      return {
        className: classNames(_.kebabCase(columnName), {
          active: columnIsActive,
          unsortable: !isColumnSortable(columnName),
          ascending: columnIsActive && _.get(order, 'ascending'),
          descending: columnIsActive && !_.get(order, 'ascending')
        }),
        key: columnName,
        onClick: () => this.onColumnHeaderClick(columnName),
        onKeyDown: handleEnter(() => this.onColumnHeaderClick(columnName)),
        scope: 'col',
        tabIndex: 0
      };
    };

    const columnTranslation = (key) =>
      _.has(key, 'title')
        ? key.title
        : I18n.t(`shared.asset_browser.result_list_table.columns.${_.snakeCase(key)}`);

    const tableHeader = (
      <thead>
        <tr>
          {columns.map((columnName) => (
            <th key={columnName} {...columnHeaderProps(columnName)}>
              <span className="column-name">{columnTranslation(columnName)}</span>
              <span className="ascending-arrow socrata-icon-arrow-up2" />
              <span className="descending-arrow socrata-icon-arrow-down2" />
            </th>
          ))}
        </tr>
      </thead>
    );

    const tableBody = (
      <tbody>
        {results.map((result) => (
          <ResultListRow
            {...resultListRowProps(result, transferOwnership, updateAudience)}
            actionElement={actionElement}
            columns={columns}
            closeOnSelect={closeOnSelect}
            locked={result.resource.locked}
            onAssetSelected={onAssetSelected}
            onClose={onClose}
            openInNewTab={openInNewTab}
            key={result.resource.id}
          />
        ))}
      </tbody>
    );

    const tableClassNames = classNames('result-list-table table table-discrete table-condensed', {
      'table-borderless': !this.props.bordered,
      'has-filter-sidebar': showFilters
    });

    return (
      <table className={tableClassNames}>
        {tableHeader}
        {tableBody}
      </table>
    );
  }
}

ResultListTable.propTypes = {
  actionElement: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  bordered: PropTypes.bool,
  changeSortOrder: PropTypes.func.isRequired,
  closeOnSelect: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  onAssetSelected: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.object,
  results: PropTypes.array.isRequired,
  selectMode: PropTypes.bool,
  showFilters: PropTypes.bool,
  updateAudience: PropTypes.func,
  transferOwnership: PropTypes.func
};

ResultListTable.defaultProps = {
  bordered: false,
  order: undefined
};

const mapStateToProps = (state, ownProps) => ({
  columns: ownProps.columns || state.assetBrowserProps.columns,
  order: state.catalog.order,
  results: state.catalog.results,
  selectMode: state.assetBrowserProps.selectMode,
  showFilters: state.assetBrowserProps.showFilters
});

const mapDispatchToProps = (dispatch) => ({
  changeSortOrder: (columnName) => dispatch(changeSortOrder(columnName))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultListTable);
