import React, { FC, useState } from 'react';
import { ForgeButton, ForgeIcon, ForgeTooltip, ForgeDialog } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { useDispatch } from 'react-redux';
const scope = 'shared.components.asset_action_bar.reset_to_default';

const ResetToDefaultButton: FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);
  const handleReset = () => {
    dispatch({ type: 'TRIGGER_PERSONALIZED_STORY_RESET' });
  };

  const handleConfirm = () => {
    handleReset();
    setTimeout(handleCloseDialog, 0);
  };

  return (
    <>
      <ForgeButton type="flat" onClick={handleOpenDialog}>
        <button
          type="button"
          data-testid="reset-to-default-button"
          aria-label={I18n.t('button', { scope: scope })}
        >
          <ForgeIcon name="restore" aria-hidden="false" external external-type="extended" />
          {I18n.t('button', { scope: scope })}
        </button>
        <ForgeTooltip position={'bottom'}>{I18n.t('tooltip', { scope: scope })}</ForgeTooltip>
      </ForgeButton>
      <ForgeDialog open={isDialogOpen} onDismiss={handleCloseDialog} data-testid="reset-dialog">
        <div id="dialog-content" style={{ color: '#000' }}>
          <header className="forge-dialog__header">
            <h2 className="forge-dialog__title" data-testid="reset-dialog-title">
              {I18n.t('dialog_header', { scope: scope })}
            </h2>
          </header>
          <section className="forge-dialog__body" style={{ width: '446px' }}>
            <p data-testid="reset-dialog-message">{I18n.t('dialog_message', { scope: scope })}</p>
          </section>
          <footer className="forge-dialog__footer" style={{ padding: '16px' }}>
            <ForgeButton onClick={handleCloseDialog} type="outlined">
              <button data-testid="reset-dialog-cancel" type="button" style={{ marginRight: '16px' }}>
                {I18n.t('dialog_cancel_button', { scope: scope })}
              </button>
            </ForgeButton>
            <ForgeButton onClick={handleConfirm} type="raised">
              <button data-testid="reset-dialog-confirm" type="button" forge-dialog-focus="true">
                {I18n.t('dialog_reset_button', { scope: scope })}
              </button>
            </ForgeButton>
          </footer>
        </div>
      </ForgeDialog>
    </>
  );
};

export default ResetToDefaultButton;
