import $ from 'jquery';
import deleteCookie from 'common/js_utils/deleteCookie';

export default function DisablePreview() {
  $('.disablePreviewMode').on('click', function(evt) {
    evt.preventDefault();
    // Apparently this is how you delete cookies?
    if ($.cookies) {
      $.cookies.del('socrata_site_chrome_preview');
    } else {
      deleteCookie('socrata_site_chrome_preview');
    }
    window.location.reload();
  });
}
