import React, { FunctionComponent } from 'react';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import './index.scss';

const i18nScope = 'shared.components.asset_action_bar.publication_action.fix_metadata_message';
const t = (key: string) => I18n.t(key, { scope: i18nScope });
interface Props {
  fixMetadataLink?: string; // must provide this or onFixMetadataClick
  fetchValidityStatus: 'success' | 'error' | 'loading' | 'idle';
  onFixMetadataClick?: () => void; // when provided will render a fix metadata button instead of the fixMetadataLink
}

export const FixMetadataMessage: FunctionComponent<Props> = ({
  fixMetadataLink,
  fetchValidityStatus,
  onFixMetadataClick
}) => {
  if (fetchValidityStatus === 'loading') return t('loading');
  if (fetchValidityStatus === 'error' || fetchValidityStatus === 'idle') return t('error_validating');

  if (!fixMetadataLink && !onFixMetadataClick) {
    console.error(
      'FixMetadataMessage component requires fixMetadataLink or onFixMetadataClick prop to be set.'
    );
    return null;
  }

  return (
    <span className="fix-metadata-message">
      {t('cant_publish')}
      <br />
      <span className="publish-to-do">
        <ForgeIcon slot="leading" name="circle_edit_outline" /> {t('filled_metadata')}
      </span>
      {onFixMetadataClick ? (
        <ForgeButton type="flat" onClick={onFixMetadataClick}>
          <button aria-label={t('fix_metadata')} data-testid="fix-metadata-button">
            <span>{t('fix_metadata')}</span>
          </button>
        </ForgeButton>
      ) : (
        <a className="fix-metadata-link" data-test-id="fix-metadata-link" href={fixMetadataLink}>
          {t('fix_metadata')}
        </a>
      )}
    </span>
  );
};

export default FixMetadataMessage;
