import { isUndefined } from 'lodash';
import { Expr, SoQLStringLiteral, SoQLNumberLiteral, WindowFunctionInfo } from 'common/types/soql';
import { ViewColumn } from 'common/types/viewColumn';
import { buildColumnRef, buildLiteral } from './expr';
import { OrderByDsl, convertOrderBy } from './orderBy';
import { QualifiedViewColumn, isString, isNumber, isViewColumn, isQualifiedViewColumn } from './util';

interface WindowFunctionDsl {
  partitions?: (QualifiedViewColumn | ViewColumn | Expr)[];
  orderings?: OrderByDsl[];
  frames?: (string | number | SoQLStringLiteral | SoQLNumberLiteral)[];
}

/**
 * @see https://www.postgresql.org/docs/9.6/tutorial-window.html
 */
export const buildWindowFunction = (info?: WindowFunctionDsl): WindowFunctionInfo => {
  if (isUndefined(info)) {
    return {
      partitions: [],
      orderings: [],
      frames: []
    };
  }

  const partitions = (info.partitions || []).map((partition) => {
    if (isQualifiedViewColumn(partition)) {
      return buildColumnRef(partition.column, partition.qualifier);
    } else if (isViewColumn(partition)) {
      return buildColumnRef(partition);
    }
    return partition;
  });

  const orderings = (info.orderings || []).map(convertOrderBy);

  const frames = (info.frames || []).map((frame) => {
    if (isString(frame) || isNumber(frame)) {
      return buildLiteral(frame);
    }
    return frame;
  });

  return { partitions, orderings, frames };
};
