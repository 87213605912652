import React from 'react';
import { ForgeDialog, ForgeToolbar, ForgeIconButton, ForgeIcon, ForgeButton } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import { showForgeDefaultToastNow } from 'common/components/ToastNotification/Toastmaster';
import TemplatedMetadataForm from './forms/TemplatedMetadataForm';
import { AssetMetadata } from '../types';
import { ManageMetadataDirectProps } from '../containers/ManageMetadataContainer';
import SaveError from '../wrappers/helpers/saveError';
import { ForgeCard } from '@tylertech/forge-react';
import { ForgeScaffold } from '@tylertech/forge-react';

const t = (key: string, scope = 'shared.dataset_management_ui', options?: any) =>
  I18n.t(key, { scope, ...options });

interface ManageMetadataState {
  showConfirmCloseModal: boolean;
}

export class ManageMetadata extends React.Component<ManageMetadataDirectProps, ManageMetadataState> {
  constructor(props: ManageMetadataDirectProps) {
    super(props);
    const { setFormState, metadata, channel, joinMetadataChannel } = props;

    this.state = {
      showConfirmCloseModal: false
    };

    this.handleDatasetFormSubmit = this.handleDatasetFormSubmit.bind(this);
    setFormState(metadata);

    if (!channel) {
      joinMetadataChannel();
    }
  }

  componentDidMount() {
    const { showFlash, showFederatedHrefMessage } = this.props;

    if (showFederatedHrefMessage) {
      showFlash(
        'href',
        'href_info',
        t('metadata_manage.dataset_tab.href_message'),
        undefined,
        t('metadata_manage.dataset_tab.here'),
        'https://support.socrata.com/hc/en-us/articles/1500005478122'
      );
    }
  }

  componentWillUnmount() {
    const { hideAllFlashMessages } = this.props;
    hideAllFlashMessages();
  }

  handleCloseAttempt = () => {
    const { form, handleCloseRequest } = this.props;

    if (!form.isDirty) {
      handleCloseRequest();
    } else {
      this.setState({ showConfirmCloseModal: true });
    }
  };

  handleMetadataChange = (newMetadata: AssetMetadata) => {
    const { markFormDirty, setFormState } = this.props;

    markFormDirty();
    setFormState(newMetadata);
  };

  hideFormSubmitFlash = () => {
    const { hideFlash } = this.props;
    hideFlash('metadata_save_success');
    hideFlash('metadata_save_error');
  };

  async handleDatasetFormSubmit() {
    const {
      setFormErrors,
      markFormDirty,
      markFormClean,
      markFormSubmitted,
      onSave,
      setFormState,
      handleCloseRequest,
      isModal,
      form: { state: newAssetMetadata }
    } = this.props;

    if (!newAssetMetadata) {
      return;
    }

    this.hideFormSubmitFlash();

    try {
      markFormClean();
      const didSave = await onSave(newAssetMetadata);
      if (didSave) {
        showForgeDefaultToastNow(t('metadata_manage.save_success_toast'));
        markFormSubmitted();
        setFormErrors({});
        // If we're rendering as a modal, we want to close after the user finishes their changes
        if (isModal) {
          setFormState(undefined);
          handleCloseRequest();
        } else {
          setFormState(newAssetMetadata);
        }
      } else {
        markFormDirty();
      }
    } catch (error) {
      console.log(error);
      showForgeDefaultToastNow(error.message);
      markFormDirty();
      if ((error as SaveError).responseError) {
        setFormErrors((error as SaveError).responseError);
      }
    }
  }

  render() {
    const {
      metadata,
      form,
      handleCloseRequest,
      hasErrors,
      isModal,
      isTabular,
      channel,
      metadataTemplates,
      templateResults,
      requestInProgress,
      setTemplateErrors,
      evaluateMetadata,
      onUploadAttachment,
      hideLicenseField = false
    } = this.props;
    const { showConfirmCloseModal } = this.state;
    const dialogAttributes = new Map([['aria-labelledby', 'metadata-dialog-title']]);

    const confirmCloseModal = (
      <ConfirmationModal
        onAgree={() => {
          this.setState({ showConfirmCloseModal: false });
          handleCloseRequest();
        }}
        agreeButtonText={t('unsaved_changes_close_button')}
        onCancel={() => this.setState({ showConfirmCloseModal: false })}
        cancelButtonText={t('continue_editing')}
        headerText={t('unsaved_changes_warning')}
      >
        {t('unsaved_changes_message')}
      </ConfirmationModal>
    );

    const pageTitle = t('metadata_manage.title');
    const closeButton = isModal ? (
      <ForgeIconButton slot="end">
        <button
          className="tyler-icons"
          onClick={this.handleCloseAttempt}
          data-testid="manage-metadata-component-modal-header-close-button"
        >
          <ForgeIcon name="close" />
        </button>
      </ForgeIconButton>
    ) : (
      <ForgeButton type="outlined" slot="end">
        <button
          type="button"
          onClick={this.handleCloseAttempt}
          data-testid="manage-metadata-component-non-modal-header-close-button"
        >
          {t('metadata_manage.edit_metadata_return_button')}
        </button>
      </ForgeButton>
    );

    const headerClassName = isModal ? 'metadata-dialog-header' : 'metadata-form-header';
    const headerToolbar = (
      <>
        <ForgeToolbar className={headerClassName}>
          <h1 slot="start" id="metadata-dialog-title" className="forge-typography--title">
            {pageTitle}
          </h1>
          {closeButton}
        </ForgeToolbar>
      </>
    );

    const footerToolbar = (
      <ForgeToolbar inverted>
        <ForgeButton type="outlined" slot="end" className="cancel-button">
          <button onClick={this.handleCloseAttempt} type="button">
            {t('metadata_manage.cancel_button')}
          </button>
        </ForgeButton>
        <ForgeButton type="unelevated" slot="end">
          <button onClick={this.handleDatasetFormSubmit} type="button" disabled={!form.isDirty || hasErrors}>
            {t('metadata_manage.save_button')}
          </button>
        </ForgeButton>
      </ForgeToolbar>
    );

    const body = (
      <>
        {showConfirmCloseModal && confirmCloseModal}
        <div id="manage-metadata-component-body">
          <section className="forge-dialog__body" tabIndex={0}>
            <TemplatedMetadataForm
              isTabular={isTabular}
              formSubmitted={form.submitted}
              handleDatasetFormSubmit={this.handleDatasetFormSubmit}
              handleMetadataChange={this.handleMetadataChange}
              assetMetadata={metadata}
              channel={channel}
              metadataTemplates={metadataTemplates}
              templateResults={templateResults}
              requestInProgress={requestInProgress}
              setTemplateErrors={setTemplateErrors}
              evaluateMetadata={evaluateMetadata}
              onUploadAttachment={onUploadAttachment}
              hideLicenseField={hideLicenseField}
            />
          </section>
        </div>
      </>
    );

    if (isModal) {
      return (
        <ForgeDialog
          open={true}
          options={{ backdropClose: false, dialogAttributes, escapeClose: false }}
          onDismiss={this.handleCloseAttempt}
        >
          {headerToolbar}
          {body}
          {footerToolbar}
        </ForgeDialog>
      );
    }

    return (
      <>
        {headerToolbar}
        <ForgeCard className="metadata-form-content-wrapper">
          <ForgeScaffold>
            <ForgeToolbar slot="header">
              <h2 id="asset-name-header" className="forge-typography--title" slot="start">
                {metadata.builtIn.name}
              </h2>
            </ForgeToolbar>
            <div slot="body">{body}</div>
            <div slot="footer">{footerToolbar}</div>
          </ForgeScaffold>
        </ForgeCard>
      </>
    );
  }
}

export default ManageMetadata;
