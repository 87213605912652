import _ from 'lodash';
import airbrake from 'common/airbrake';
import { fetchTranslation } from 'common/locale';
import { getCurrentDomain } from 'common/currentDomain';
import { FeatureFlags } from 'common/feature_flags';
import { mapIdsToParam } from 'common/js_utils';
import { appToken as getAppToken } from 'common/http';

/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */

const CETERA_CATALOG_PATH = '/api/catalog/v1';
const CETERA_USER_PATH = `${CETERA_CATALOG_PATH}/users`;
const CETERA_AUTOCOMPLETE_PATH = `${CETERA_CATALOG_PATH}/autocomplete`;
const CETERA_DOMAINS_PATH = `${CETERA_CATALOG_PATH}/domains`;
const DEFAULT_LIMIT = 6;
const DEFAULT_ORDER = 'relevance';

const getOffset = (pageNumber, limit) => (pageNumber - 1) * limit;

let errorMessage;

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    if (response.status === 400) {
      return response.json().then((json) => {
        if (json.error === 'Sum of `offset` and `limit` cannot exceed 10000') {
          throw new Error('offset_too_large');
        }
      });
    }

    errorMessage = response.statusText;

    if (response.status === 502) {
      errorMessage = fetchTranslation('common.error.connection_502');
    }
    if (response.status === 503) {
      errorMessage = fetchTranslation('common.error.unavailable_503');
    }
    if (response.status === 504) {
      errorMessage = fetchTranslation('common.error.timeout_504');
    }

    try {
      airbrake.notify({
        error: `Error fetching cetera results: ${errorMessage}`,
        context: { component: 'AssetSelector' }
      });
    } catch (err) {}

    throw new Error(errorMessage);
  }
}

function parseJSON(response) {
  return response.json();
}

function handleError(error) {
  try {
    airbrake.notify({
      error: `Error fetching cetera results: ${error}`,
      context: { component: 'AssetSelector' }
    });
  } catch (err) {}
  console.error(error);
  throw error;
}

export const convertSearchBoostsToQueryObjects = (searchBoosts) => {
  let queryObjects = [];
  searchBoosts.forEach((searchBoost) => {
    const searchBoostObject = {
      key: `boostDomains[${searchBoost.cname}]`,
      value: searchBoost.multiplier
    };

    queryObjects.push(searchBoostObject);
  });

  return queryObjects;
};

export const ceteraUtils = (() => {
  const currentDomain = getCurrentDomain();

  const fetchOptions = {
    credentials: 'same-origin',
    headers: {
      'X-Socrata-Host': currentDomain,
      'User-Agent': 'SocrataFrontend/1.0 (+https://socrata.com/)'
    }
  };
  const normalizeCeteraQueryParams = ({
    approvalStatus = null,
    assetSelector = null,
    audience = null,
    category = null,
    collectionParent = null,
    customMetadataFilters = {},
    derivedFrom = null,
    domains = null,
    explicitlyHidden = null,
    enrolledInArchival = null,
    filters = [],
    forUser = null,
    idFilters = [],
    limit = DEFAULT_LIMIT,
    only = null,
    order = DEFAULT_ORDER,
    outcomeApplicationStatus = null,
    pageNumber = 1,
    parentIds: parentIdFilters = [],
    provenance = null,
    published = null,
    q = null,
    searchBoosts = [],
    searchContext = currentDomain,
    sharedTo = null,
    showVisibility = null,
    tags = null,
    targetAudience = null,
    visibility = null
  }) => {
      const parameters = {
        approval_status: approvalStatus,
        audience,
        categories: category,
        collection_parent: collectionParent,
        ...customMetadataFilters,
        derived_from: derivedFrom,
        domains,
        explicitly_hidden: explicitlyHidden,
        enrolled_in_archival: enrolledInArchival ? enrolledInArchival : null,
        asset_selector: assetSelector,
        filters: filters,
        for_user: mapIdsToParam(forUser, 'for_user'),
        ids: idFilters,
        limit,
        offset: getOffset(pageNumber, limit),
        only: only,
        order,
        outcome_application_status: outcomeApplicationStatus,
        parent_ids: parentIdFilters,
        published,
        provenance,
        q,
        search_context: searchContext,
        shared_to: mapIdsToParam(sharedTo, 'shared_to'),
        show_unsupported_data_federated_assets: false,
        show_visibility: showVisibility,
        tags,
        target_audience: targetAudience,
        visibility
      };

    /*
     * Note, changes to filters and search options here must match the corresponding implementation in
     * platform-ui/frontend/app/controllers/internal_asset_manager_controller.rb
     */

    const reduceUriEncodedQueryParameter = (result, value, key) => {
      if (!_.isNull(key) && !_.isNull(value)) {
        return result.concat([{ key, value }]);
      } else {
        return result;
      }
    };

    const reducePossibleArray = (reducedParameters, possibleArray, keyName) => {
      return _.reduce(_.compact(_.castArray(possibleArray)), (result, value) =>
        result.concat({key: keyName, value }), reducedParameters);
    };

    const mapQueryParamsToKeyValuePairs = (queryParams) => {
      let parameters = [];
      queryParams.forEach((paramPair) => {
        parameters.push({
          key: paramPair.queryParam,
          value: paramPair.paramValue
        });
      });
      return parameters;
    };

    const ids = _.get(parameters, 'ids', []);
    const parentIds = _.get(parameters, 'parent_ids', []);
    const categories = _.get(parameters, 'categories', []);

    // Omit some parameters which require special treatment
    delete parameters.approval_status;
    delete parameters.audience;
    delete parameters.outcome_application_status;
    delete parameters.ids;
    delete parameters.parent_ids;
    delete parameters.shared_to;
    delete parameters.filters;
    delete parameters.for_user;
    delete parameters.categories;
    delete parameters.target_audience;

    let reducedParameters = _.reduce(parameters, reduceUriEncodedQueryParameter, []);
    reducedParameters = reducePossibleArray(reducedParameters, approvalStatus, 'approval_status');
    reducedParameters = reducePossibleArray(reducedParameters, outcomeApplicationStatus, 'outcome_application_status');
    reducedParameters = reducePossibleArray(reducedParameters, sharedTo, 'shared_to');
    reducedParameters = reducePossibleArray(reducedParameters, forUser, 'for_user');
    reducedParameters = reducePossibleArray(reducedParameters, audience, 'audience');
    reducedParameters = reducePossibleArray(reducedParameters, ids, 'ids');
    reducedParameters = reducePossibleArray(reducedParameters, parentIds, 'parent_ids');
    reducedParameters = reducePossibleArray(reducedParameters, categories, 'categories');
    reducedParameters = reducePossibleArray(reducedParameters, targetAudience, 'target_audience');

    reducedParameters = reducedParameters.concat(mapQueryParamsToKeyValuePairs(filters));
    reducedParameters = reducedParameters.concat(convertSearchBoostsToQueryObjects(searchBoosts));

    return reducedParameters;
  };

  // Query param string used for multiple Cetera queries (results query, facet counts query).
  const ceteraQueryString = (params) => {
    return normalizeCeteraQueryParams(params)
    .map(({ key, value }) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  };

  return {
    normalizeCeteraQueryParams,
    ceteraQueryString,
    query: (queryOptions, isBrowse3) => {
      const queryString = ceteraQueryString(queryOptions);
      const fetchUrl = `${CETERA_CATALOG_PATH}?${queryString}`;

      if (isBrowse3) {
        const browse3Header = {
          'X-Browse3': '',
          'X-App-Token': getAppToken()
        };
        const headers = Object.assign(fetchOptions.headers, browse3Header);
        fetchOptions.headers = headers;
      }

      // Calls fetch and returns the promise
      return fetch(fetchUrl, fetchOptions).
        then(checkStatus).
        then(parseJSON).
        catch(handleError);
    },

    facetCountsQuery: (queryOptions) => {
      // For the facet counts query, the "limit" limits the number of facet values returned.
      // We don't want to limit the number of facet values, so strip `limit` out of the options.
      const queryString = ceteraQueryString({
        ...queryOptions,
        limit: null
      });
      const ceteraFacetsPath = `${CETERA_DOMAINS_PATH}/${currentDomain}/facets`;
      const fetchUrl = `${ceteraFacetsPath}?${queryString}`;

      // Calls fetch and returns the promise
      return fetch(fetchUrl, fetchOptions).
        then(checkStatus).
        then(parseJSON).
        catch(handleError);
    },

    domainUsersQuery: (queryOptions) => {
      const queryString = ceteraQueryString(queryOptions);
      const fetchUrl = `${CETERA_USER_PATH}?${queryString}`;

      return fetch(fetchUrl, fetchOptions).
        then(checkStatus).
        then(parseJSON).
        catch(handleError);
    },

    autocompleteQuery: (searchTerm, otherFilters = {}) => {
      var path = window.location.pathname;
      if (path === '/browse' || path === '/' || path === '/browse3' || path === '/browse-preview') {
        const audience = FeatureFlags.value('data_catalog_audience_level') === 'internal'
          ? ['public', 'site']
          : 'public';
        otherFilters = {
          ...otherFilters,
          audience: audience,
          published: true,
          explicitlyHidden: false
        };
      }
      const queryString = ceteraQueryString({ ...otherFilters, order: 'name', q: searchTerm });
      const fetchUrl = `${CETERA_AUTOCOMPLETE_PATH}?${queryString}`;

      return fetch(fetchUrl, fetchOptions).
        then(checkStatus).
        then(parseJSON).
        catch(handleError);
    },

    mapToAssetSelectorResult: (ceteraResults) => {
      const resultIsFederated = (resultDomain) => {
        return resultDomain !== window.location.hostname;
      };

      return ceteraResults.map((ceteraResult) => {
        const ceteraResultResource = ceteraResult.resource;

        const isPublic = ceteraResult.metadata.is_public;
        const isInternal = ceteraResult.metadata.visible_to_site;
        let scope;
        if (!isPublic && !isInternal) {
          scope = 'private';
        } else {
          scope = isPublic ? 'public' : 'site';
        }

        return {
          _resource: ceteraResultResource,
          ..._.pick(
            ceteraResultResource, 'id', 'uid', 'name', 'description', 'provenance', 'createdAt', 'updatedAt'
          ),
          categories: ceteraResult.classification.categories,
          domain: ceteraResult.metadata.domain,
          isFederated: resultIsFederated(ceteraResult.metadata.domain),
          sourceDomain: ceteraResult.metadata.domain,
          isPublic: isPublic,
          link: ceteraResult.link,
          previewImageUrl: ceteraResult.preview_image_url,
          scope: scope,
          tags: ceteraResult.classification.tags,
          type: ceteraResultResource.type,
          viewCount: parseInt(ceteraResultResource.page_views.page_views_total, 10)
        };
      });
    },
  };
})();

export default ceteraUtils;
