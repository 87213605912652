import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { hasDatasetErrors } from 'common/dsmapi/ManageMetadataComponent/components/utils/helpers';
import ManageMetadata from '../components/ManageMetadata';
import { hideAllFlashMessages, hideFlashMessage, showFlashMessage } from '../reduxStuff/actions/flashMessage';
import {
  markFormClean,
  markFormDirty,
  markFormSubmitted,
  setFormErrors,
  setFormState,
  dismissMetadataComponent,
  setTemplateErrors
} from '../reduxStuff/actions/forms';
import { evaluateMetadata, joinMetadataChannel } from '../reduxStuff/actions/metadataChannel';
import ManageMetadataComponentContext from '../context';
import { FieldInput } from 'common/types/metadataTemplate';
import { RootState, AppDispatch } from '../reduxStuff/store';
import {
  FormErrors,
  FlashMessageKinds,
  DatasetLicenses,
  AssetMetadata,
  OnUploadAttachmentSignature
} from '../types';

interface OwnProps {
  datasetLicenses: DatasetLicenses;
  onSave: (metadata: AssetMetadata) => Promise<boolean>;
  onUploadAttachment: OnUploadAttachmentSignature;
  onClose: () => null;
  metadata: AssetMetadata;
  isModal: boolean;
  showFederatedHrefMessage: boolean;
  isTabular: boolean;
  websocketToken?: string;
  hideLicenseField?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  form: state.forms.metadataForm,
  hasErrors: hasDatasetErrors(state.forms),
  channel: state.metadataChannel.channel,
  metadataTemplates: state.metadataChannel.metadataTemplates,
  templateResults: state.metadataChannel.templateResults,
  requestInProgress: !!state.metadataChannel.latestRequestId
});

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  showFlash: (
    kind: FlashMessageKinds,
    id: string,
    message: string,
    hideAfterMS: number | undefined = undefined,
    helpMessage: string | undefined = undefined,
    helpUrl: string | undefined = undefined
  ) =>
    dispatch(
      showFlashMessage({
        kind,
        id,
        message,
        hideAfterMS,
        helpMessage,
        helpUrl
      })
    ),
  hideFlash: (id: string) => dispatch(hideFlashMessage(id)),
  hideAllFlashMessages: () => dispatch(hideAllFlashMessages()),
  handleCloseRequest: () => dispatch(dismissMetadataComponent(ownProps.onClose)),
  setFormErrors: (errors: FormErrors) => dispatch(setFormErrors(errors)),
  setFormState: (state: AssetMetadata | undefined) => dispatch(setFormState(state)),
  markFormDirty: () => dispatch(markFormDirty()),
  markFormClean: () => dispatch(markFormClean()),
  markFormSubmitted: () => dispatch(markFormSubmitted()),
  joinMetadataChannel: () => dispatch(joinMetadataChannel(ownProps.websocketToken)),
  setTemplateErrors: (errors: string[]) => dispatch(setTemplateErrors(errors)),
  evaluateMetadata: (inputs: FieldInput[]) => dispatch(evaluateMetadata(inputs))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

// Everything the actual component will see, NOT the container's props
export type ManageMetadataDirectProps = Omit<OwnProps, 'datasetLicenses'> & PropsFromRedux;

// Both the datasetLicenses and the attachementsUploadUrl are used in one spot.
// Let's just shove them in a React Context so we don't have to bother tracking them anywhere else.
const ContextWrapper: React.FunctionComponent<OwnProps & PropsFromRedux> = ({
  datasetLicenses,
  ...props
}) => {
  return (
    <ManageMetadataComponentContext.Provider value={{ datasetLicenses }}>
      <ManageMetadata {...props} />
    </ManageMetadataComponentContext.Provider>
  );
};

const DragAndDropWrappedComponent = DragDropContext(HTML5Backend)(ContextWrapper);

export default connector(DragAndDropWrappedComponent) as React.ComponentType<OwnProps>;
