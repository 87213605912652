import React from 'react';
import { isArray as _isArray, every as _every, isString as _isString, uniq as _uniq } from 'lodash';
import { Option, some, none } from 'ts-option';
import { ForgeAutocomplete, ForgeChipField, ForgeChip, ForgeIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { fieldValueForCref, lookupLabel } from '../utils/helpers';
import {
  FieldT,
  FieldValue,
  MultiSelectComponents,
  MultiSelectWithParentComponents
} from 'common/types/metadataTemplate';
import { ColumnRef } from 'common/types/soql';
import PrivateFieldIcon from 'common/FormatColumn/PrivateFieldIcon/PrivateFieldIcon';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab') =>
  I18n.t(key, { scope });

export interface MultiSelectInputProps {
  instance: FieldT;
  value: FieldValue;
  multiSelectComponents: MultiSelectComponents | MultiSelectWithParentComponents;
  isRequired: boolean;
  isRestrictedForUser: boolean;
  isPrivate: boolean;
  inErrorState: boolean;
  onUpdateField: (cref: ColumnRef, value: any) => void;
}

// Because ForgeAutocomplete doesn't play nice with RTL, we need to export this seperately to test it.
// When we upgrade to Forge 3, we should be able to put this back in and test it normally.
export const multiSelectFilter = (filterText: string, options: string[], instance: FieldT) => {
  const multiOptions = options.filter((item) => item.toLowerCase().includes(filterText.toLowerCase()));
  return multiOptions.map((option: string, index) => {
    return { label: lookupLabel(index, instance, option), value: option };
  });
};

const MultiSelectInput: React.FunctionComponent<MultiSelectInputProps> = ({
  instance,
  value,
  multiSelectComponents: { options, inputCref },
  isRequired,
  isRestrictedForUser,
  isPrivate,
  inErrorState,
  onUpdateField
}) => {
  const currentLabels: string[] = [];
  const currentValue = fieldValueForCref(value.inputs, inputCref)
    .flatMap((examinedValue: unknown): Option<string[]> => {
      if (_isArray(examinedValue) && _every(examinedValue, _isString)) {
        examinedValue.forEach((optionInValueArray: string) => {
          currentLabels.push(lookupLabel(options.indexOf(optionInValueArray), instance, optionInValueArray));
        });
        return some(examinedValue as string[]);
      }
      return none;
    })
    .getOrElseValue([]);

  return (
    <ForgeAutocomplete
      mode="stateless"
      filter={(filterText: string) => multiSelectFilter(filterText, options, instance)}
      on-forge-autocomplete-select={(event: CustomEvent) => {
        onUpdateField(inputCref, _uniq([...currentValue, event.detail.value]));
      }}
      data-testid={`multiselect-field-${instance.field_name}`}
    >
      <ForgeChipField required={isRequired} invalid={inErrorState}>
        {isPrivate && (
          <span slot="leading">
            <PrivateFieldIcon />
          </span>
        )}
        <label slot="label" htmlFor="multiselect-field-input">
          {' '}
          {instance.display_name}{' '}
        </label>
        <input disabled={isRestrictedForUser} autoComplete="off" type="text" id="multiselect-field-input" />
        {currentValue.map((option, index) => (
          <ForgeChip
            key={option}
            slot="member"
            type="field"
            dense
            on-forge-chip-delete={() => {
              onUpdateField(
                inputCref,
                currentValue.filter((examinedOption) => examinedOption !== option)
              );
            }}
            value={option}
            data-testid={`multiselect-field-${instance.field_name}-option-${option}`}
          >
            {currentLabels[index]}
          </ForgeChip>
        ))}
        {isRestrictedForUser && <span slot="helper-text">{t('subtitles.restricted_field')}</span>}
        <ForgeIcon slot="trailing" data-forge-dropdown-icon name="arrow_drop_down" />
      </ForgeChipField>
    </ForgeAutocomplete>
  );
};

export default MultiSelectInput;
