import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { handleEnter } from 'common/dom_helpers/keyPressHelpers';
import I18n from 'common/i18n';
import { getEnabledFilters } from 'common/components/AssetBrowser/lib/helpers/enabled_filters';

import ClearFilters from './clear_filters';
import AssetTypesFilter from './asset_types_filter';
import AuthorityFilter from './authority_filter';
import CategoryFilter from './category_filter';
import CustomFacetFilters from './custom_facet_filters';
import OwnedByFilter from './owned_by_filter';
import IntendedAudienceFilter from './intended_audience_filter';
import RecentlyViewedFilter from './recently_viewed_filter';
import EnrolledInArchivalFilter from './enrolled_in_archival_filter';
import AwaitingApprovalFilter from './awaiting_approval_filter';
import SourceFilter from './source_filter';
import TagFilter from './tag_filter';
import VersionFilter from './version_filter';
import VisibilityFilter from './visibility_filter';
import RequesterFilter from './requester';

export class CatalogFilters extends Component {
  state = { filterContentOpen: true };

  handleFilterContentToggleClick = () => {
    this.setState({ filterContentOpen: !this.state.filterContentOpen });
  };

  render() {
    const { activeTab, showAwaitingApprovalFilter } = this.props;

    const { filterContentOpen } = this.state;

    const filterContentClass = classNames('filter-content', { hidden: !filterContentOpen });

    const openFiltersButton = !filterContentOpen ? (
      <button
        className="open-filters filter-content-toggle"
        onClick={this.handleFilterContentToggleClick}
        onKeyDown={handleEnter(this.handleFilterContentToggleClick, true)}
        aria-label={I18n.t('shared.asset_browser.filters.desktop.expand')}
      >
        <span
          aria-hidden="true"
          className="socrata-icon-arrow-left"
          title={I18n.t('shared.asset_browser.filters.desktop.expand')}
        />
      </button>
    ) : null;

    const closeFiltersButton = filterContentOpen ? (
      <button
        className="close-filters filter-content-toggle"
        onClick={this.handleFilterContentToggleClick}
        onKeyDown={handleEnter(this.handleFilterContentToggleClick, true)}
      >
        {I18n.t('shared.asset_browser.filters.desktop.hide')}
        <span
          aria-hidden="true"
          className="socrata-icon-arrow-right"
          title={I18n.t('shared.asset_browser.filters.desktop.contract')}
        />
      </button>
    ) : null;

    const filterHeader = (
      <div className="catalog-filters-header">
        <ClearFilters />
        {closeFiltersButton}
      </div>
    );

    const enabledFilters = getEnabledFilters(activeTab);

    return (
      <div className="catalog-filters">
        {openFiltersButton}

        <div className={filterContentClass}>
          {filterHeader}
          <form>
            <div className="filter-switches">
              {enabledFilters.recentlyViewed && <RecentlyViewedFilter />}
              {enabledFilters.enrolledInArchival && <EnrolledInArchivalFilter />}
              {enabledFilters.intendedAudience && <IntendedAudienceFilter />}
              {showAwaitingApprovalFilter && <AwaitingApprovalFilter />}
            </div>
            {enabledFilters.version && <VersionFilter />}
            <AssetTypesFilter />
            {enabledFilters.source && <SourceFilter />}
            {enabledFilters.authority && <AuthorityFilter />}
            {enabledFilters.ownedBy && <OwnedByFilter />}
            {enabledFilters.visibility && <VisibilityFilter />}
            {enabledFilters.category && <CategoryFilter />}
            {enabledFilters.tag && <TagFilter />}
            {enabledFilters.customFacet && <CustomFacetFilters />}
            {enabledFilters.requester && <RequesterFilter />}
          </form>
        </div>
      </div>
    );
  }
}

CatalogFilters.propTypes = {
  activeTab: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  activeTab: state.header.activeTab
});

export default connect(mapStateToProps)(CatalogFilters);
