import React, { MutableRefObject, useRef } from 'react';
import {
  trim as _trim,
  uniq as _uniq,
  reject as _reject,
  isEqual as _isEqual,
  cloneDeep as _cloneDeep
} from 'lodash';
import { ForgeAutocomplete, ForgeChip, ForgeChipField } from '@tylertech/forge-react';
import { IAutocompleteOption } from '@tylertech/forge';
import I18n from 'common/i18n';
import airbrake from 'common/airbrake';
import { defaultHeaders } from 'common/http';
import { getCurrentDomain } from 'common/currentDomain';
import { UserInputField } from '../../types';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab.labels') =>
  I18n.t(key, { scope });

export interface TagsInputProps {
  field: UserInputField<any>;
  handleAddTag: (newTags: string[]) => void;
  inErrorState: boolean;
  disabled: boolean;
}

const fetchTagsRequest = (tagsSearch: string) => {
  const domain = getCurrentDomain();
  const url = `/api/catalog/v1/tags/autocomplete?domains=${domain}&q=${tagsSearch}`;
  const fetchOptions: RequestInit = {
    credentials: 'same-origin',
    headers: defaultHeaders
  };
  return fetch(url, { ...fetchOptions, method: 'GET' });
};

// exported for testing
export const fetchTagsList = async (tagSearch: string): Promise<IAutocompleteOption<string>[]> => {
  if (tagSearch === '') {
    return [];
  }

  try {
    const response = await fetchTagsRequest(tagSearch);
    const json: { results: { tag_text: string }[] } = await response.json();

    return json.results.map((tagResult) => {
      return { label: tagResult.tag_text, value: tagResult.tag_text };
    });
  } catch (error) {
    airbrake.notify({
      error,
      context: { component: 'TagsInput' }
    });
    return [];
  }
};

const TagsInput: React.FunctionComponent<TagsInputProps> = ({ field, handleAddTag, inErrorState }) => {
  const onAddTag = (value: string) => {
    const tags: string[] = field.value ? _cloneDeep(field.value) : [];
    // This is a hack to handle to allow adding entirely new tags
    // without messing with params for this method in MultiSelect
    // because that would be a mess
    value
      .split(',')
      .map((tag) => _trim(tag)) // Remove starting/ending whitespace
      .filter((tag) => !!tag) // Remove empty strings
      .map((tag) => tag.toLowerCase()) // You can probably guess
      .forEach((tag) => tags.push(tag));

    handleAddTag(_uniq(tags));
  };

  const onRemoveSelectedTag = (tag: string) => {
    const currentTags = field.value || [];
    const filteredTags = _reject(currentTags, (currentTag) => _isEqual(currentTag, tag));
    handleAddTag(filteredTags);
  };

  const tags: string[] = field.value || [];
  const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  return (
    <div id="metadata-component-tags-input">
      <ForgeAutocomplete
        mode="stateless"
        filter={fetchTagsList}
        on-forge-autocomplete-select={(event: CustomEvent) => {
          onAddTag(event.detail.value);
          inputRef.current.value = '';
        }}
        filterOnFocus={false}
        allowUnmatched={true}
        filterFocusFirst={false}
        on-forge-chip-field-member-added={(event: CustomEvent) => {
          onAddTag(event.detail);
        }}
        data-testid={'tags-input-field'}
      >
        <ForgeChipField className="metadata-modal-width" invalid={inErrorState}>
          {tags.map((chipTag) => (
            <ForgeChip
              className="metadata-tag-chip"
              key={chipTag}
              slot="member"
              type="field"
              dense
              on-forge-chip-delete={() => onRemoveSelectedTag(chipTag)}
              value={chipTag}
              data-testid={`tags-input-chip-${chipTag}`}
            >
              {chipTag}
            </ForgeChip>
          ))}
          <label slot="label" htmlFor="chip-field-input">
            {t('tags_and_keywords')}
          </label>
          <input ref={inputRef} autoComplete="off" type="text" id="chip-field-input" />
        </ForgeChipField>
      </ForgeAutocomplete>
    </div>
  );
};

export default TagsInput;
