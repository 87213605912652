import React, { useState, useEffect } from 'react';
import { Revision } from 'common/types/revision';
import ManageMetadataComponent from '../../reduxStuff/reduxWrapper';
import { AssetMetadata, OnUploadAttachmentSignature } from '../../types';
import { assetMetadataToRevisionMetadata, revisionToAssetMetadata } from './conversion/revisions';
import { updateRevision } from './helpers/dsmapiHelpers';
import { xhrPromise, DsmapiAttachmentUploadResponse } from '../helpers/attachmentUploadHelpers';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { assetIdFor, fetchApprovalsGuidanceV2 } from 'common/core/approvals/index_new';
import { requireApprovalRequestWithdrawal } from 'common/components/AssetActionBar/components/publication_action/utils';
import { View } from 'common/types/view';
import { isTabular, isInitialDatasetDraft } from 'common/views/view_types';

export interface RevisionWrapperForManageMetadataComponentProps
  extends Omit<
    React.ComponentProps<typeof ManageMetadataComponent>,
    'metadata' | 'onSave' | 'attachmentsUploadUrl' | 'onUploadAttachment' | 'isTabular' | 'websocketToken'
  > {
  view: View;
  metadata: Revision;
  onSave: (revision: Revision) => Promise<void>;
}

export const RevisionWrapperForManageMetadataComponent: React.FunctionComponent<
  RevisionWrapperForManageMetadataComponentProps
> = ({ datasetLicenses, onSave, onClose, metadata: revision, isModal, showFederatedHrefMessage, view }) => {
  const [guidance, setGuidance] = useState<GuidanceSummaryV2 | undefined>(undefined);

  useEffect(() => {
    let isWrapperMounted = true;
    const getGuidance = async () => {
      if (isWrapperMounted) {
        const workflowGuidance = await fetchApprovalsGuidanceV2(
          assetIdFor(revision.fourfour, revision.revision_seq, false)
        );
        setGuidance(workflowGuidance);
      }
    };

    getGuidance();

    return () => {
      isWrapperMounted = false;
    };
  }, [revision.fourfour, revision.revision_seq]);

  const convertedAssetMetadata = revisionToAssetMetadata(revision);

  const onSaveToDsmapi = async (assetMetadata: AssetMetadata) => {
    const canProceed = guidance && (await requireApprovalRequestWithdrawal(guidance));

    if (!canProceed) {
      return false;
    }

    const convertedRevisionMetadata = assetMetadataToRevisionMetadata(assetMetadata, revision);

    const updatedRevision = await updateRevision(
      convertedRevisionMetadata,
      revision.fourfour,
      revision.revision_seq
    );

    await onSave(updatedRevision);
    return true;
  };

  const onUploadAttachment: OnUploadAttachmentSignature = async (file, onProgress) => {
    const attachmentsUploadUrl = `/api/publishing/v1/revision/${revision.fourfour}/${revision.revision_seq}/attachment`;

    const response = await xhrPromise(attachmentsUploadUrl, file, onProgress);

    const parsedResponse: DsmapiAttachmentUploadResponse = JSON.parse(response.responseText);

    return {
      asset_id: parsedResponse.file_id,
      filename: parsedResponse.filename,
      name: parsedResponse.filename,
      created_at: 'now'
    };
  };

  const tabularLike =
    isTabular(view) ||
    // initial draft dataset that is not yet blobby or href-y
    (isInitialDatasetDraft(view) && (!revision.href || revision.href.length === 0) && !revision.blob_id);

  return (
    <ManageMetadataComponent
      isTabular={tabularLike}
      datasetLicenses={datasetLicenses}
      onSave={onSaveToDsmapi}
      onClose={onClose}
      metadata={convertedAssetMetadata}
      isModal={isModal}
      showFederatedHrefMessage={showFederatedHrefMessage}
      onUploadAttachment={onUploadAttachment}
      hideLicenseField={false} // Revision based assets all allow licences at the moment.
    />
  );
};
