import React, { useEffect, useState, useContext } from 'react';
import { includes as _includes } from 'lodash';
import { some, none, Option } from 'ts-option';
import { ForgeOption, ForgeSelect } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { useErrors } from '../utils/hooks';
import { hasRequiredExprs } from 'common/dsmapi/metadataTemplate';
import ExpressionInputFields from './ExpressionInputFields';
import { Json } from 'common/components/ObjectEditor';
import LabelAndErrors from 'common/components/LabelAndErrors';
import { LocalizedCategory, getEnabledCategories } from 'common/core/categories';
import Spinner from 'common/components/Spinner';
import TagsInput from '../inputs/TagsInput';
import AttachmentsInput from '../../containers/AttachmentsInputContainer';
import { fieldValueForCref, fieldParentValueForCref } from '../utils/helpers';
import ManageMetadataComponentContext from '../../context';
import { ColumnRef } from 'common/types/soql';
import { FieldProps, OnUploadAttachmentSignature, UserInputField } from '../../types';
import { Attachment } from 'common/types/revision';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab') =>
  I18n.t(key, { scope });
const editMetadataLabel = (key: string) =>
  t(key, 'shared.dataset_management_ui.metadata_manage.edit_metadata');
const translationWithOptions = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.dataset_management_ui.metadata_manage.edit_metadata', ...options });

export interface BuiltInProps extends FieldProps {
  onUploadAttachment: OnUploadAttachmentSignature;
  showLicenseField?: boolean;
}

const CategoryColumnRef: ColumnRef = {
  type: 'column_ref',
  qualifier: null,
  value: 'category'
};

const LicenseIdColumnRef: ColumnRef = {
  type: 'column_ref',
  qualifier: null,
  value: 'license_id'
};

const AttachmentsColumnRef: ColumnRef = {
  type: 'column_ref',
  qualifier: null,
  value: 'attachments'
};

const TagsColumnRef: ColumnRef = {
  type: 'column_ref',
  qualifier: null,
  value: 'tags'
};

const getLabelForBuiltin = (fieldName: string): string | null =>
  ({
    name: editMetadataLabel('dataset_title'),
    description: editMetadataLabel('brief_description'),
    row_label: editMetadataLabel('row_label'),
    category: editMetadataLabel('category'),
    tags: editMetadataLabel('tags_keywords'),
    license_id: editMetadataLabel('license_type'),
    attribution: editMetadataLabel('attribution'),
    attribution_link: editMetadataLabel('attribution_link'),
    contact_email: editMetadataLabel('email_address'),
    resource_name: editMetadataLabel('resource_name')
  })[fieldName];

const getPlaceholderForBuiltin = (displayName: string) =>
  ({
    name: editMetadataLabel('dataset_title'),
    description: editMetadataLabel('brief_description_prompt'),
    row_label: editMetadataLabel('row_label_prompt'),
    category: null,
    tags: editMetadataLabel('dataset_tags'),
    license_id: null,
    attribution: editMetadataLabel('dataset_attribution'),
    attribution_link: editMetadataLabel('dataset_url'),
    contact_email: editMetadataLabel('dataset_email'),
    resource_name: editMetadataLabel('dataset_resource_name')
  })[displayName];

const Builtin: React.FunctionComponent<BuiltInProps> = ({
  inProgress,
  identifier,
  onUpdateField,
  value,
  hasSubmitted,
  assetMetadata,
  onUploadAttachment,
  showLicenseField
}) => {
  const { datasetLicenses } = useContext(ManageMetadataComponentContext);
  const fieldInstance = identifier.instances[0];
  const { display_name: displayName, field_name: fieldName } = fieldInstance;
  const [errors, setHasReceivedInput] = useErrors(hasSubmitted, inProgress, value);
  const isRequired = hasRequiredExprs(identifier.instances, identifier.qualifier);
  const inErrorState = errors.length > 0;

  const fieldProps: UserInputField<any> = {
    name: fieldName,
    value,
    label: getLabelForBuiltin(fieldName) || displayName,
    placeholder: getPlaceholderForBuiltin(displayName),
    isRequired
  };

  let body = null;

  const expressionInputFieldNames = [
    'name',
    'description',
    'row_label',
    'attribution',
    'attribution_link',
    'contact_email',
    'resource_name'
  ];

  if (_includes(expressionInputFieldNames, fieldName)) {
    body = (
      <ExpressionInputFields
        inProgress={inProgress}
        qualifier={identifier.qualifier}
        inputType={fieldName === 'description' ? 'textarea' : 'text'}
        inErrorState={inErrorState}
        isRequired={isRequired}
        isRestrictedForUser={false} // Currently only custom field can be marked 'restricted'
        isPrivate={false}
        field={fieldInstance}
        value={value}
        getLabel={(inputFieldName: string) => getLabelForBuiltin(inputFieldName) || displayName}
        getPlaceholder={(inputFieldName: string) => getPlaceholderForBuiltin(inputFieldName)}
        onUpdateField={(cref: ColumnRef, newValue: Json) => {
          setHasReceivedInput();
          onUpdateField(cref, newValue);
        }}
      />
    );
  } else if (fieldName === 'category') {
    // Rails screws up the categories when putting the actual categories onto window.
    // I can't figure out *where* this is happening, and I'm worried that if I fixed it,
    // I'd break things. FFS, *this* is why we don't do this sort of thing. The core api
    // is fine as it is, why do we need rails in the mix re-writing all the core apis to
    // something stupid
    // Update: Removed the dependency on the rails thing, but once the flag is flipped over
    // and there's no going back, we should remove the window.initialState.datasetCategories
    // call because it's an extra call for every page load.

    // EN-56238: fix this incorrect usage of hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [categories, setCategories] = useState<Option<LocalizedCategory[]>>(none);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // Can't have an async function as the top level for useEffect...that implies
      // something is wrong. async should always bubble up?
      const triggerSetCategories = async () => {
        setCategories(some(await getEnabledCategories()));
      };
      triggerSetCategories();
    }, []);

    const noValueLabel = t('select_a_value');

    const spinner = <Spinner />;

    body = categories.match({
      none: () => spinner,
      some: (coreCategories) => {
        const subCategoryParentValue = fieldParentValueForCref(
          value.inputs,
          CategoryColumnRef,
          coreCategories
        ).getOrElseValue(undefined);
        const categorySelect = (
          <ForgeSelect
            label={t('labels.category')}
            data-testid="builtin-categories"
            value={fieldValueForCref(value.inputs, CategoryColumnRef).getOrElseValue(noValueLabel)}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              if (event.currentTarget.value === noValueLabel) {
                onUpdateField(CategoryColumnRef, null);
              } else {
                onUpdateField(CategoryColumnRef, event.currentTarget.value);
              }
              setHasReceivedInput();
            }}
            invalid={inErrorState}
          >
            {[
              <ForgeOption value={noValueLabel} key={0}>
                {noValueLabel}
              </ForgeOption>,
              ...coreCategories.map(({ name, localized, value: categoryValue }, index) => {
                const secondaryLabel =
                  categoryValue.parent &&
                  translationWithOptions('sub_category_of_parent', { parentName: categoryValue.parent });
                return (
                  <ForgeOption value={name} key={index + 1} secondaryLabel={secondaryLabel}>
                    {localized || name}
                  </ForgeOption>
                );
              })
            ]}
            {subCategoryParentValue && (
              <span slot="helper-text">
                {translationWithOptions('sub_category_of_parent', { parentName: subCategoryParentValue })}
              </span>
            )}
          </ForgeSelect>
        );
        return categorySelect;
      }
    });
  } else if (fieldName === 'license_id') {
    if (!showLicenseField) {
      return null;
    }
    const licenseLabel = t('labels.license_label');
    body = (
      <ForgeSelect
        className="metadata-modal-width"
        label={licenseLabel}
        value={fieldValueForCref(value.inputs, LicenseIdColumnRef).getOrElseValue('')}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          onUpdateField(LicenseIdColumnRef, event.currentTarget.value);
          setHasReceivedInput();
        }}
        invalid={inErrorState}
        data-testid="builtin-license"
      >
        {[
          datasetLicenses.map((license, index) => {
            return (
              <ForgeOption key={index} value={license.value}>
                {license.title}
              </ForgeOption>
            );
          })
        ]}
      </ForgeSelect>
    );
  } else if (fieldName === 'tags') {
    body = (
      <TagsInput
        field={{
          ...fieldProps,
          value: fieldValueForCref(value.inputs, TagsColumnRef).getOrElseValue('')
        }}
        inErrorState={inErrorState}
        disabled={false}
        handleAddTag={(newTags: string[]) => {
          setHasReceivedInput();
          onUpdateField(TagsColumnRef, newTags);
        }}
      />
    );
  } else if (fieldName === 'attachments') {
    body = (
      <AttachmentsInput
        buttonText={t('add_attachments')}
        attachments={fieldValueForCref(value.inputs, AttachmentsColumnRef).getOrElseValue(
          assetMetadata.builtIn.attachments || []
        )}
        handleAttachmentChange={(newAttachments: Attachment[]) =>
          onUpdateField(AttachmentsColumnRef, newAttachments)
        }
        onUploadAttachment={onUploadAttachment}
      />
    );
  } else {
    console.warn("Can't find renderer for ", fieldName);
  }

  return (
    <LabelAndErrors
      errors={errors}
      isPrivate={false}
      isRequired={isRequired}
      name={fieldName}
      label={getLabelForBuiltin(fieldName) || undefined}
      useLabels={false}
    >
      {body}
    </LabelAndErrors>
  );
};

export default Builtin;
