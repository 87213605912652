import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import FieldTitle from '../FieldTitle';
import './index.scss';

import Checkbox from 'common/components/Checkbox';
import Dropdown from 'common/components/Dropdown';
import { ALERT_SNOOZE_PERIODIC } from '../../constants';

const scope = 'shared.components.create_alert_modal.alert_trigger_page';

class AlertSnoozeOptions extends Component {
  handleSnoozeTimeChange = (e) => {
    if (e.target.validity.valid) {
      this.props.onAlertSnoozeTimeChange(e.target.value);
    }
  };

  renderSnoozePeriodicTypes() {
    const { alertSnoozePeriodic, onAlertSnoozePeriodicChange, alertSnoozeEnabled } = this.props;
    const snoozePeriodicTypes = [
      {
        title: I18n.t('days', { scope }),
        value: ALERT_SNOOZE_PERIODIC.DAYS
      },
      {
        title: I18n.t('hours', { scope }),
        value: ALERT_SNOOZE_PERIODIC.HOURS
      },
      {
        title: I18n.t('minutes', { scope }),
        value: ALERT_SNOOZE_PERIODIC.MINUTES
      }
    ];

    const alertTypeOptions = {
      disabled: !alertSnoozeEnabled,
      options: snoozePeriodicTypes,
      onSelection: (option) => onAlertSnoozePeriodicChange(option.value),
      showOptionsBelowHandle: true,
      size: 'small',
      value: alertSnoozePeriodic
    };

    return <Dropdown {...alertTypeOptions} />;
  }

  renderErrorInfo() {
    const { alertSnoozeTime } = this.props;

    if (Number(alertSnoozeTime) <= 0) {
      return <div className="error-info">{I18n.t('snooze_time_error', { scope })}</div>;
    }
  }

  renderSnoozeEnableField() {
    const { alertSnoozeEnabled, onToggleAlertSnooze } = this.props;
    const checkboxAttributes = {
      checked: alertSnoozeEnabled,
      id: 'alert-snooze-enable-check-box',
      onChange: onToggleAlertSnooze
    };

    return (
      <Checkbox {...checkboxAttributes}>
        <span>{I18n.t('snooze_alerts_for', { scope })}</span>
      </Checkbox>
    );
  }

  render() {
    const { alertSnoozeTime, alertSnoozeEnabled } = this.props;
    return (
      <div className="alert-snooze-options">
        <FieldTitle
          flyoutId="alert-snooze-flyout-info"
          flyoutText={I18n.t('snooze_flyout_text', { scope })}
          title={I18n.t('auto_snooze', { scope })}
        />
        <form className="alert-snooze-items">
          {this.renderSnoozeEnableField()}
          <input
            type="text"
            disabled={!alertSnoozeEnabled}
            pattern="[0-9]*"
            value={alertSnoozeTime}
            onChange={(e) => this.handleSnoozeTimeChange(e)}
          />
          {this.renderSnoozePeriodicTypes()}
        </form>
        {this.renderErrorInfo()}
      </div>
    );
  }
}

AlertSnoozeOptions.propTypes = {
  alertSnoozeEnabled: PropTypes.bool,
  alertSnoozeTime: PropTypes.string,
  alertSnoozePeriodic: PropTypes.string,
  onAlertSnoozeTimeChange: PropTypes.func,
  onAlertSnoozePeriodicChange: PropTypes.func,
  onToggleAlertSnooze: PropTypes.func
};

export default AlertSnoozeOptions;
