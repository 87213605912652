/**
 * Deletes/expires a cookie by name.
 * @param name String - The name of the cookie to remove
 * @returns Boolean or Undefined:
 *   - TRUE = The cookie was deleted.
 *   - FALSE = The cookie was unable to be deleted or there were multiple copies
 *       not specific to the current domain path.
 *   - UNDEFINED = The cookie did not exist.
 */
export const deleteCookie: (name: string) => boolean | undefined = (name) => {
  const regexp = new RegExp(`(^|; ?)${name}=`, 'g');
  let response: boolean | undefined = true;

  if (regexp.test(decodeURIComponent(document.cookie))) {
    // Set an expiration date in the past (Unix Epoch)
    const expires = new Date(0).toUTCString();

    document.cookie = `${name}=; expires=${expires}; path=/`;

    response = !regexp.test(decodeURIComponent(document.cookie));
  } else {
    response = undefined;
  }

  return response;
};

export default deleteCookie;
